<template>
  <v-main class="grey lighten-4">
    <div>
      <router-view />
    </div>
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
  }
</script>
